import React from "react"

import AccountWrapper from "../../components/account/account-wrapper"
import MyOrderHistory from "../../components/account/my-order-history"

const OrderHistory = ({ location }) => {
  return (
    <AccountWrapper location={location} accountStep="order-history">
      <MyOrderHistory location={location} />
    </AccountWrapper>
  )
}

export default OrderHistory
