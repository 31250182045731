import React from "react"
import { memo } from "react"
import styled from "styled-components"
import LinkWrapper from "../../reusable-components/link-wrapper"

import { formatPrice, slugify, roundTo2 } from "../../../lib/utils"

// ======================
// 	     COMPONENT
// ======================

const OrderItem = memo(({ lineItem = {} }) => {
  // Variations should use the parent name slug, not the variation name slug
  let slug = lineItem?.slug
  if (lineItem?.size) {
    slug = slugify(lineItem?.title)
  }

  // Build product URL
  const productUrl = slug
    ? `/${process.env.GATSBY_PRODUCTS_SLUG}/${slug}/`
    : `/${process.env.GATSBY_SHOP_PAGE_SLUG}/`

  let squares = 0
  for (let i = 0; i < lineItem?.meta_data?.length; ++i) {
    const metaItem = lineItem?.meta_data?.[i]
    if (metaItem?.key == "square_metres_of_tiles_per_box") {
      squares = parseFloat(metaItem?.value)
      break
    }
  }
  const area = roundTo2(squares * lineItem?.quantity)

  return (
    <OrderItemsStyling>
      <LinkWrapper to={productUrl || "/"} as={productUrl ? LinkWrapper : "div"}>
        <DetailsStyling>
          <div dangerouslySetInnerHTML={{ __html: lineItem?.image }} />
          <ProductNameStyling>
            <h5>{lineItem?.name ? lineItem?.name : lineItem?.title}</h5>
            <h6>{lineItem?.sku}</h6>
            {!area ? null : (
              <h6>
                {lineItem?.quantity} boxes = {area} m<sup>2</sup>
              </h6>
            )}
          </ProductNameStyling>
        </DetailsStyling>
      </LinkWrapper>
      <PriceStyling>
        {lineItem?.quantity} <span>x</span>{" "}
        {formatPrice({ price: lineItem?.unit_price })}
      </PriceStyling>
    </OrderItemsStyling>
  )
})

export default OrderItem

// ======================
// 	      STYLES
// ======================
const OrderItemsStyling = styled.div`
  padding: 20px;
  &:not(:last-child) {
    border-bottom: 2px dashed ${({ theme }) => theme.colors.lightgrey3};
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
  }
`

const DetailsStyling = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 150px;
    min-width: 150px;
    margin-right: 20px;
    box-shadow: 0 2px 7px -4px ${({ theme }) => theme.colors.lightgrey3};
    border-radius: 2px;
    overflow: hidden;
  }
  h5 {
    font-size: 1.3rem;
    font-weight: 500;
  }

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    h5 {
      /* font-size: 1rem; */
    }
    img {
      width: 100px;
      min-width: 100px;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    align-items: flex-start;
  }
`

const ProductNameStyling = styled.div`
  margin-right: 20px;
`

const PriceStyling = styled.div`
  font-size: 1.3rem;
  font-weight: 500;
  white-space: pre;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    font-size: 18px;
  }
  span {
    color: ${({ theme }) => theme.colors.lightgrey5};
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    margin-left: 120px;
    margin-top: 10px;
  }
`
