import React, { useContext, useState } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"

import OrderItem from "./order-item"
import OrderTitle from "./order-title"
import LocationPinIcon from "../../../images/icons/location-pin-icon"
import DeliveryIcon from "../../../images/icons/delivery-icon"
import SecurePaymentIcon from "../../../images/icons/secure-payment"
import AddressCard from "../../../images/icons/address-card"

import CrossShape from "../../reusable-components/cross-shape"
import CompactLoader from "../../reusable-components/loaders/compact-loader"
import ToastNotification from "../../theme/header/toast-notification"

import { formatPrice } from "../../../lib/utils"
import CartContext from "../../../context/cart/cart-context"

// ======================
// 	    COMPONENT
// ======================

const OrderCard = ({ order = {} }) => {
  // Prepare state
  const [open, setOpen] = useState(false)
  const [failedProductMessage, setFailedProductMessage] = useState(false)
  const [retryOrderLoading, setRetryOrderLoading] = useState(false)

  const { addToCart, clearCart, openCartDrawer } = useContext(CartContext)

  // Prepare variables
  const orderNumber = order?.id
  const items = order?.line_items || []
  const status = order?.status
  const billing = order?.billing
  const discount =
    parseFloat(order?.discount_total) + parseFloat(order?.discount_tax)
  const payment_method = order?.payment_method_title
  const total = order?.total
  const shipping_total =
    parseFloat(order?.shipping_total) + parseFloat(order?.shipping_tax)
  const shipping_method = order?.shipping_method
  const meta_data = order?.meta_data || []

  const collectionMessage = meta_data?.find?.(
    (met) => met?.key == "collection_message"
  )?.value

  const payOnDelivery = payment_method == "cod" || payment_method == "ccondel"

  // Calculate quantity of items
  let countItemsQuantity = 0
  for (let index = 0; index < items.length; index++) {
    countItemsQuantity = countItemsQuantity + items[index].quantity
  }

  // Retry order button handler
  const handleRetryOrder = async () => {
    setRetryOrderLoading(true)
    setFailedProductMessage(false)

    await clearCart()
    for (let i = 0; i < items.length; i++) {
      try {
        await addToCart(
          {
            id: items[i].product_id,
            quantity: items[i].quantity,
          },
          i == items.length - 1
        )
      } catch (error) {
        let errorString = "Failed to add product to cart"
        if (error.message) {
          errorString = error.message
          console.log("Error Response: ", errorString)
        } else {
          console.log("Error Response: ", error)
        }
        // setFailedProductMessage(`Cannot add ${items[i].name} to cart`)
        setFailedProductMessage(errorString)
      }
    }

    setRetryOrderLoading(false)
    openCartDrawer(true)
  }

  // Difference between "needsPayment" and "wasPaid" is eg. a "refunded" or "cancelled" order does not need payment
  // but it also was not paid

  // eg. to show "Pay Now" button
  const needsPayment =
    status == "on-hold" ||
    status == "pending" ||
    (status == "processing" && payment_method == "bacs") ||
    status == "failed"
  // eg. to show "Paid" label
  const wasPaid =
    status == "completed" || (status == "processed" && !payOnDelivery)

  let summary_title = "Order Payment is being Processed"

  if (status == "pending") {
    summary_title = "Order is Pending Payment"
  } else if (status == "completed") {
    summary_title = "Order has been Completed"
  } else if (status == "on-hold") {
    if (order?.payment_method === "quoterequest") {
      summary_title = "Quote Requested"
    } else {
      summary_title = "Order is on Hold"
    }
  } else if (status == "cancelled") {
    summary_title = "Order has been Cancelled"
  } else if (status == "refunded") {
    summary_title = "Order Items have been Refunded"
  } else if (status == "failed") {
    summary_title = "Order Payment has Failed"
  } // else if (status == "processing") handled by default

  // Get Special Delivery Instruction meta data
  const deliveryInstruction = meta_data?.find?.(
    (met) => met?.key == "delivery_instruction"
  )?.value

  return (
    <>
      <OrderTitle
        orderNumber={order?.id}
        orderNumberFormatted={order?.order_number}
        orderDate={order?.date_created.date}
        payDate={order?.date_paid?.date}
        paymentMethod={order?.payment_method}
      />
      <OrderCardStyling>
        {failedProductMessage && (
          <ToastNotification
            location={location}
            message={failedProductMessage}
          />
        )}
        <SummaryStyling>
          <StyledH4>{summary_title}</StyledH4>
          <ActionStyling>
            <StyledButton onClick={() => setOpen(!open)} open={open}>
              <div className="addMargin">
                {order?.payment_method === "quoterequest"
                  ? "Quote Details"
                  : "Order Details"}
              </div>
              <CrossShape className={open ? "cross-open" : "filter-closed"} />
            </StyledButton>
            {!needsPayment ||
            order?.payment_method === "quoterequest" ? null : (
              <StyledButton
                onClick={() => {
                  navigate(`/checkout/payment/?orderid=${orderNumber}`)
                }}
              >
                Pay now
              </StyledButton>
            )}
            {status == "cancelled" && (
              <StyledButton onClick={handleRetryOrder}>
                {retryOrderLoading ? (
                  <CompactLoader padding="0" center={true} margin="auto" />
                ) : (
                  "Retry Order"
                )}
              </StyledButton>
            )}
          </ActionStyling>
        </SummaryStyling>
        <DetailContentStyling open={open}>
          <div>
            <IconLabelStyling>
              <LocationPinIcon /> <span>Shipping Address</span>
            </IconLabelStyling>
            <p>
              {billing.first_name} {billing.last_name}
            </p>
            <p>{billing.address_1}</p>
            <p>{billing.address_2}</p>
            <p>{billing.city}</p>
            <p>{billing.state}</p>
            <p>{billing.postcode}</p>
            <p>{billing.phone}</p>
            {deliveryInstruction ? (
              <p className="deliveryNote">{deliveryInstruction}</p>
            ) : null}
          </div>
          <OrderMethodStyling>
            {shipping_method && (
              <>
                <IconLabelStyling>
                  <DeliveryIcon /> <span>Delivery Method</span>
                </IconLabelStyling>
                <p>{shipping_method}</p>
                <>
                  {status != "completed" &&
                    collectionMessage &&
                    collectionMessage != "" && (
                      <CollectionMessage>{collectionMessage}</CollectionMessage>
                    )}
                </>
              </>
            )}
            {payment_method && order?.payment_method !== "quoterequest" && (
              <>
                <IconLabelStyling>
                  <SecurePaymentIcon /> <span>Payment Method</span>
                </IconLabelStyling>
                <p>{payment_method}</p>
              </>
            )}
          </OrderMethodStyling>
          <OrderSummaryStyling>
            <IconLabelStyling>
              <AddressCard />{" "}
              <span>
                {order?.payment_method === "quoterequest"
                  ? "Quote Summary"
                  : "Order Summary"}
              </span>
            </IconLabelStyling>
            <p>
              Total Items: <span>{countItemsQuantity}</span>
            </p>
            {discount > 0 ? (
              <p>
                Discount:
                <span>{discount}</span>
              </p>
            ) : null}
            {shipping_total > 0 && (
              <p>
                Delivery Cost:{" "}
                <span>{formatPrice({ price: shipping_total })}</span>
              </p>
            )}
            <p>
              {order?.payment_method === "quoterequest"
                ? "Quote Total"
                : "Order Total"}
              : <span>{formatPrice({ price: total })}</span>
            </p>
          </OrderSummaryStyling>
        </DetailContentStyling>
        <Items>
          {items.map((lineItem, i) => {
            return <OrderItem lineItem={lineItem} key={i} />
          })}
        </Items>
      </OrderCardStyling>
    </>
  )
}

export default OrderCard

// ======================
// 	       STYLES
// ======================
const StyledButton = styled.button`
  display: flex;
  align-items: center;
  overflow: hidden;
  & + button {
    margin-left: 20px;
  }
  .addMargin {
    margin-right: 20px;
  }
  #cross,
  #cross:after {
    background-color: ${({ theme }) => theme.colors.blue};
    transition: 0.2s all ease-in-out;
  }
  &:hover #cross,
  &:hover #cross:after {
    background-color: white;
    transition: 0.2s all ease-in-out;
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    & + button,
    div:first-child {
      margin: 0;
    }
    & + button {
      margin-bottom: 10px;
    }
    width: 100%;
  }
`

const OrderCardStyling = styled.div`
  box-shadow: 0 13px 27px 0 rgba(97, 97, 97, 0.25);
  border-radius: 2px;
  margin-bottom: 6rem;

  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    margin-bottom: 60px;
  }
`
const SummaryStyling = styled.div`
  padding: 20px;
  border-bottom: 2px dashed ${({ theme }) => theme.colors.lightgrey3};
  align-items: center;
  display: flex;
  justify-content: space-between;
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    flex-direction: column;
    > div {
      margin: 10px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      &:nth-child(2) {
        flex-direction: column-reverse;
      }
    }
  }
`

const ActionStyling = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    width: 100%;
  }
`
const StyledH4 = styled.h4`
  font-size: 1.6rem;
  font-weight: 500;
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    margin-bottom: 12px;
    text-align: center;
  }
  @media (max-width: ${({ theme }) => theme.breakTiny}) {
    font-size: 1.3rem;
  }
`

const DetailContentStyling = styled.div`
  padding: 20px;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  border-bottom: ${({ open, theme }) =>
    open ? `2px dashed ${theme.colors.lightgrey3}` : "0px dashed transparent"};
  overflow: hidden;
  max-height: ${({ open }) => (open ? "500px" : "0")};
  height: 100%;
  transition: all 0.3s ease-in-out;
  > div {
    overflow: hidden;
    height: ${({ open }) => (open ? "100%" : "0")};
    transition: ${({ open }) =>
      open ? "height 0.35s ease-in-out" : "height 0s ease-in-out 0.3s"};
  }
  p {
    margin: 0;
  }
  .deliveryNote {
    font-style: italic;
    font-weight: bold;
  }

  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    grid-template-columns: 1fr;
    max-height: ${({ open }) => (open ? "1000px" : "0")};
  }
`

const OrderMethodStyling = styled.div`
  p {
    justify-content: space-between;
    display: flex;
    margin-bottom: 20px;
  }

  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    p:last-of-type {
      margin-bottom: 0;
    }
  }
`

const OrderSummaryStyling = styled.div`
  p {
    justify-content: space-between;
    display: flex;
  }
`
const Items = styled.div`
  height: 100%;
`

const IconLabelStyling = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  span {
    margin-left: 20px;
    font-size: 1.3rem;
  }
`
const CollectionMessage = styled.p``
