import React from "react"
import styled from "styled-components"

import OrdersList from "./orders/orders-list"

const MyOrderHistory = () => {
  return (
    <>
      <StyledH2>Quote and Order History</StyledH2>
      <OrdersList />
    </>
  )
}

export default MyOrderHistory

// ===============
//     STYLES
// ===============
const StyledH2 = styled.h2`
  padding-bottom: 20px;
`
