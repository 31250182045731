import React, { useState, useEffect, useContext } from "react"
import styled from "styled-components"

import { getUser } from "../../../context/auth/auth-functions"

import AuthContext from "../../../context/auth/auth-context"
import { getAllOrders } from "../../../api/account"
import EllipsisLoader from "../../reusable-components/loaders/elipsis-loader"
import OrderCard from "./order-card"

import LinkWrapper from "../../reusable-components/link-wrapper"

// Tabs component. Should be refactored at some point
const OrdersList = ({ givenOrderList = [] }) => {
  const [loading, setLoading] = useState(true)
  const [apiError, setApiError] = useState("")
  const [orders, setOrders] = useState(givenOrderList)
  const { loggedInState, performLogout } = useContext(AuthContext)

  // Fetch all orders from WC
  useEffect(() => {
    if (givenOrderList.length > 0) {
      return setLoading(false)
    }

    if (loggedInState === true) {
      const { token } = getUser()
      setLoading(true)
      getAllOrders(token, performLogout)
        .then(async (allOrders) => {
          try {
            // Loop through orders and push to array
            // This helps to avoid accidentally using an error response or something
            const newOrders = []
            for (let i = 0; i < allOrders.length; i += 1) {
              newOrders.push({ ...allOrders[i] })
            }
            setOrders(newOrders)
            setLoading(false)
          } catch (e) {
            setOrders([])
            setLoading(false)
            console.log("Failed to process newOrders: ", e)
          }
        })
        .catch((e) => {
          setOrders([])
          if (e.message) {
            setApiError(e.message)
          } else {
            setApiError("Oh no! Fetching orders failed")
          }
        })
    } else {
      setLoading(false)
    }
  }, [])

  // While loading, show loader
  if (loading) {
    return <EllipsisLoader />
  }

  if (orders.length === 0) {
    return (
      <NoOrdersStyling>
        <h3>You have no orders.</h3>
        <LinkWrapper to={`/${process.env.GATSBY_SHOP_PAGE_SLUG}/`}>
          Go Shopping
        </LinkWrapper>
      </NoOrdersStyling>
    )
  }

  return (
    <>
      {orders.map((order, i) => (
        <OrderCard key={i} order={order} />
      ))}
    </>
  )
}

export default OrdersList
// ======================
// 	       STYLES
// ======================

const NoOrdersStyling = styled.div`
  text-align: center;
  h3 {
    font-weight: 500;
    margin-bottom: 20px;
  }
`
