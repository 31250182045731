import React from "react"

const LocationPin = ({ fill = "#003A8D" }) => (
  <svg
    width="24px"
    height="27px"
    viewBox="0 0 14 17"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon" transform="translate(-5.000000, -4.000000)" fill={fill}>
        <path
          d="M11.9999875,6.62499374 C13.4474137,6.62499374 14.6249812,7.80256128 14.6249812,9.24998748 C14.6249812,10.6974137 13.4474137,11.8749812 11.9999875,11.8749812 C10.5525613,11.8749812 9.37499374,10.6974137 9.37499374,9.24998748 C9.37499374,7.80256128 10.5525613,6.62499374 11.9999875,6.62499374 Z M11.9999875,10.9999917 C12.9649472,10.9999917 13.7499917,10.2149472 13.7499917,9.24998748 C13.7499917,8.28502778 12.9649472,7.49999166 11.9999875,7.49999166 C11.0350278,7.49999166 10.2499917,8.28502778 10.2499917,9.24998748 C10.2499917,10.2149472 11.0350278,10.9999917 11.9999875,10.9999917 Z M11.9999875,4 C14.8994862,4 17.249975,6.35048878 17.249975,9.24998748 C17.249975,11.3667465 16.5125233,11.9578668 12.5395713,17.7175042 C12.2788183,18.0941475 11.72113,18.0940941 11.4604304,17.7175042 C7.4874517,11.9578668 6.75,11.3667465 6.75,9.24998748 C6.75,6.35048878 9.10048878,4 11.9999875,4 Z M11.9999875,16.9590107 C15.8091711,11.4503263 16.3749771,11.0133881 16.3749771,9.24998748 C16.3749771,8.08136539 15.9199076,6.98273138 15.0935623,6.15641271 C14.2672436,5.33006732 13.1686096,4.87499791 11.9999875,4.87499791 C10.8313654,4.87499791 9.73273138,5.33006732 8.90641271,6.15641271 C8.08006732,6.98273138 7.62499791,8.08136539 7.62499791,9.24998748 C7.62499791,11.0134949 8.19115104,11.4508337 11.9999875,16.9590107 Z M15.062476,16.0978096 C17.3916025,16.4529837 18.9999666,17.1980991 18.9999666,18.0624426 C18.9999666,19.2704781 15.8658303,20.2499374 11.9999833,20.2499374 C8.13413636,20.2499374 5,19.2704781 5,18.0624426 C5,17.1980991 6.60836409,16.4529837 8.93749061,16.0975158 L8.93749061,16.9416186 C7.23481218,17.172144 6.09374739,17.5872126 6.09374739,18.0624426 C6.09374739,18.7873173 8.73815408,19.3752065 11.9999833,19.3752065 C15.2618125,19.3752065 17.9062192,18.7875843 17.9062192,18.0627097 C17.9062192,17.5872126 16.7651544,17.172144 15.062476,16.9418856 L15.062476,16.0978096 Z"
          id="Combined-Shape"
        ></path>
      </g>
    </g>
  </svg>
)

export default LocationPin
